<template>
  <b-card no-body class="card-employee-task">
    <b-card-header>
      <b-card-title>Top 5 Employee</b-card-title>
    </b-card-header>

    <!-- body -->

    <b-card-body>
      <template>
        <b-table responsive="" :items="items" :fields="fields">
          <template #cell(employeeData)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.employeeData[0].photo"
                  :text="avatarText(data.item.employeeData[0].first_name)"
                />
              </template>
              <b-link class="font-weight-bold d-block text-nowrap">
                <span class="align-text-top text-capitalize"
                  >{{ data.item.employeeData[0].first_name }}
                  {{ data.item.employeeData[0].last_name }}</span
                >
              </b-link>
            </b-media>
          </template>

          <template #cell(qty_product)="data">
            <b-media vertical-align="center" v-if="data.item.qty_product">
              {{ data.item.qty_product }}
            </b-media>
            <b-media vertical-align="center" v-else> 0 </b-media>
          </template>

          <template #cell(qty_service)="data">
            <b-media vertical-align="center" v-if="data.item.qty_service">
              {{ data.item.qty_service }}
            </b-media>
            <b-media vertical-align="center" v-else> 0 </b-media>
          </template>

          <template #cell(total_product)="data">
            <b-media vertical-align="center">
              $
              {{ parseFloat(data.item.total) }}
            </b-media>
          </template>
        </b-table>
      </template>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardHeader,
  BCardBody,
  BAvatar,
  BTable,
  BMedia,
  BLink,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
/* eslint-disable global-require */

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,

    BAvatar,

    BTable,
    BMedia,
    BLink,
  },
  props: {
    dashboardDataGlobalTop: {
      required: false,
    },
    recharge: {
      required: true,
    },
  },

  data() {
    return {
      fields: [
        {
          key: "employeeData",
          label: "Name",
        },
        {
          key: "qty_product",
          label: "product",
        },
        {
          key: "qty_service",
          label: "service",
        },
        {
          key: "total_product",
          label: "earnings",
        },
      ],
      items: this.dashboardDataGlobalTop,
      avatarText,
    };
  },
};
</script>
