<template>
  <b-card title="Sales">
    <div class="d-flex justify-content-between flex-wrap">
      <div v-if="recharge" class="mb-1 mb-sm-0">
        <span class="text-muted">Mela Beauty</span>
      </div>
      <div v-else class="mb-1 mb-sm-0">
        <span class="text-muted"></span>
      </div>
      <div v-if="recharge" class="d-flex align-content-center mb-1 mb-sm-0">
        <h1 class="font-weight-bolder">$ {{ data | format_number }}</h1>
      </div>
      <div v-else class="d-flex align-content-center mb-1 mb-sm-0">
        <h1 class="font-weight-bolder">$ 0</h1>
        <div class="pt-25 ml-75">
          <b-badge variant="light-secondary">
            <feather-icon
              icon="ArrowUpIcon"
              size="12"
              class="text-success align-middle"
            />
            <span class="font-weight-bolder align-middle"></span>
          </b-badge>
        </div>
      </div>
    </div>

    <!-- echart -->
    <app-echart-line :option-data="option" />
  </b-card>
</template>

<script>
import { BCard, BBadge } from "bootstrap-vue";
import AppEchartLine from "@core/components/charts/echart/AppEchartLine.vue";

export default {
  components: {
    BCard,
    AppEchartLine,
    BBadge,
  },
  props: {
    data: {
      default: null,
    },
    dayData: {
      required: false,
    },
    dashboardSalesRangeSeries: {
      required: false,
    },
    recharge: {
      type: Boolean,
      required: true,
    },
  },
  filters: {
    format_number: function (value) {
      return parseInt(value).toLocaleString();
    },
  },
  data(props) {
    return {
      option: {
        xAxisData: this.dayData,
        series: this.dashboardSalesRangeSeries,
      },
    };
  },
};
</script>
