<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Statistics Sales Year</b-card-title>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-bar-chart
        :height="400"
        :data="chartjsData.latestBarChart.data"
        :options="chartjsData.latestBarChart.options"
        :dashboardtYearMonth="dashboardtYearMonth"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import ChartjsComponentBarChart from "./charts-components/ChartjsComponentBarChart.vue";

import { $themeColors } from "@themeConfig";

const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",

  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  successColorShade: "#cba052",
};

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    flatPickr,
    ChartjsComponentBarChart,
  },
  props: {
    dashboardtYearMonth: {
      required: false,
    },
    dashboardtYearMonthSales: {
      required: false,
    },
  },
  data(props) {
    const dataY = this.dashboardtYearMonthSales.slice();
    dataY.sort();
    const valueY = dataY.pop();

    const chartjsData = {
      latestBarChart: {
        data: {
          labels: this.dashboardtYearMonth,
          datasets: [
            {
              data: this.dashboardtYearMonthSales,
              backgroundColor: chartColors.successColorShade,
              borderColor: "transparent",
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: "bottom",
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 500,
                  min: 0,
                  max: this.valueY,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
      },
    };
    return {
      chartjsData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
