import axios from "@axios";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getDashboardSales", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/getOneCategory/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addCountry(ctx, countryData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/addCategory", { countryData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    updateCountry(ctx, queryParams) {
      const id = queryParams.model.value.id;
      delete queryParams.model.value.id;
      return new Promise((resolve, reject) => {
        axios
          .put(`/updateCategory/${id}`, { params: queryParams.model.value })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateCountryStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const status = { status: queryParams.status };
        axios
          .put(`/updateCategoryStatus/${queryParams.id}`, { params: status })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteCountry(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/deleteCategory/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchHelpers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getHelpes", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getHelperSales(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getHelperSales", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getRelationCategoriesServices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getRelationCategoriesServices", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    addRelationCategoryService(ctx, relationData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/addRelationCategoryService", { relationData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateRelationServicesStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const status = { status: queryParams.status };
        axios
          .put(`/updateRelationServicesStatus/${queryParams.id}`, {
            params: status,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteRelationServicesStatus(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/deleteRelationServicesStatus/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getRelationCategoriesProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getRelationCategoriesProducts", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    addRelationCategoryProduct(ctx, relationData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/addRelationCategoryProduct", { relationData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateRelationProductsStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const status = { status: queryParams.status };
        axios
          .put(`/updateRelationProductsStatus/${queryParams.id}`, {
            params: status,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteRelationProductsStatus(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/deleteRelationProductsStatus/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
