import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const typeFilter = ref(null);
  const rangeDateFilter = ref(null);

  const dashboardDataGlobal = ref();
  const dashboardSalesRange = ref([]);
  const dashboardSalesRangeSeries = ref([]);
  const itemsDataOffice = ref([]);
  const recharge = ref(false);
  const dashboardDataGlobalTop = ref();
  const dashboardtCustomers = ref();
  const dashboardtProducts = ref();
  const dashboardtServices = ref();
  const dashboardtYearMonth = ref();
  const dashboardtYearMonthSales = ref();

  watch([typeFilter, rangeDateFilter], () => {
    const dashboardDataGlobal = ref();
    const dashboardtYearMonth = ref();
    const dashboardtYearMonthSales = ref();

    recharge.value = false;
    fetchUsers();
  });

  const fetchUsers = (ctx, callback) => {
    const { officeEmployee_officeId, rolId } = JSON.parse(
      localStorage.getItem("userData")
    ).dataUser;

    store
      .dispatch("app-user/fetchUsers", {
        type: typeFilter.value,
        rangeDate: rangeDateFilter.value,
        rolId: rolId,
        officeId: officeEmployee_officeId,
      })
      .then((response) => {
        const {
          data,
          fabio,
          series,
          office,
          employeeData,
          customers,
          salesProducts,
          salesService,
          monthYear,
          salesGlobalGroupYear,
        } = response.data;
        const arrayDataOffice = [];
        dashboardDataGlobal.value = data.global;
        dashboardSalesRange.value = fabio;
        dashboardSalesRangeSeries.value = series;
        dashboardDataGlobalTop.value = employeeData;
        dashboardtCustomers.value = customers;
        dashboardtProducts.value = salesProducts;
        dashboardtServices.value = salesService;
        dashboardtYearMonth.value = monthYear;
        dashboardtYearMonthSales.value = salesGlobalGroupYear;

        office.map((data, item) => {
          arrayDataOffice.push({
            label: data.name,
            value: data.id,
            id: item,
          });
        });
        itemsDataOffice.value = arrayDataOffice;
        recharge.value = true;
      })
      .catch((err) => {
        toast({
          component: ToastificationContent,
          props: {
            title: `Error fetching dashboardDataGlobal list ${err}`,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchUsers,
    dashboardDataGlobal,
    dashboardSalesRange,
    dashboardSalesRangeSeries,
    itemsDataOffice,
    typeFilter,
    rangeDateFilter,
    recharge,
    dashboardDataGlobalTop,
    dashboardtCustomers,
    dashboardtProducts,
    dashboardtServices,
    dashboardtYearMonth,
    dashboardtYearMonthSales,
  };
}
