<template>
  <section id="dashboard-analytics">
    <dashboard-filters
      :items-data-office="itemsDataOffice"
      :type-filter.sync="typeFilter"
      :range-date-filter.sync="rangeDateFilter"
    />

    <b-row class="match-height">
      <b-col lg="7" md="12">
        <echart-line
          v-if="recharge"
          :recharge="recharge"
          :data="dashboardDataGlobal"
          :dayData="dashboardSalesRange"
          :dashboardSalesRangeSeries="dashboardSalesRangeSeries"
        />
        <h1 v-else>
          <echart-line
            :recharge="recharge"
            data=""
            dayData="[]"
            dashboardSalesRangeSeries=""
          />
        </h1>
      </b-col>
      <b-col lg="5" md="12">
        <card-advance-employee-task
          v-if="recharge"
          :recharge="recharge"
          :dashboardDataGlobalTop="dashboardDataGlobalTop"
        />
        <span v-else>
          <card-advance-employee-task
            :recharge="recharge"
            :dashboardDataGlobalTop="dashboardDataGlobalTop"
        /></span>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="12" cols="12">
        <card-statistics-group
          v-if="recharge"
          :customers="dashboardtCustomers"
          :dashboardtProducts="dashboardtProducts"
          :dashboardtServices="dashboardtServices"
        />
        <h1 v-else>
          <card-statistics-group
            :customers="0"
            :dashboardtProducts="0"
            :dashboardtServices="0"
          />
        </h1>
      </b-col>
      <b-col md="12">
        <chartjs-bar-chart
          v-if="recharge"
          :dashboardtYearMonth="dashboardtYearMonth"
          :dashboardtYearMonthSales="dashboardtYearMonthSales"
        />
        <h1 v-else>
          <chartjs-bar-chart
            :dashboardtYearMonth="[0]"
            :dashboardtYearMonthSales="[0]"
          />
        </h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import store from "@/store";
import { BRow, BCol } from "bootstrap-vue";

import { kFormatter } from "@core/utils/filter";

import EchartLine from "./echart/EchartLine.vue";
import CardStatisticsGroup from "./CardStatisticsGroup.vue";
import CardAdvanceEmployeeTask from "./CardAdvanceEmployeeTask.vue";
import DashboardFilters from "./DashboardFilters.vue";
import useDashboardList from "./useDashboardList";
import dashboardStoreModule from "./dashboardStoreModule";
import { ref, onUnmounted } from "@vue/composition-api";
import ChartjsBarChart from "./ChartjsBarChart.vue";

export default {
  components: {
    BRow,
    BCol,
    EchartLine,
    CardStatisticsGroup,
    CardAdvanceEmployeeTask,
    DashboardFilters,
    ChartjsBarChart,
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    kFormatter,
  },
  setup({ emit }) {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, dashboardStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchUsers,
      dashboardDataGlobal,
      dashboardSalesRange,
      dashboardSalesRangeSeries,
      itemsDataOffice,
      typeFilter,
      rangeDateFilter,
      recharge,
      dashboardDataGlobalTop,
      dashboardtCustomers,
      dashboardtProducts,
      dashboardtServices,
      dashboardtYearMonth,
      dashboardtYearMonthSales,
    } = useDashboardList();

    fetchUsers();
    const dataY = 15000;
    console.log(dashboardtYearMonthSales);
    return {
      dashboardDataGlobal,
      dashboardSalesRange,
      dashboardSalesRangeSeries,
      fetchUsers,
      itemsDataOffice,
      typeFilter,
      rangeDateFilter,
      recharge,
      dashboardDataGlobalTop,
      dashboardtCustomers,
      dashboardtProducts,
      dashboardtServices,
      dashboardtYearMonth,
      dashboardtYearMonthSales,
      dataY,
    };
  },
};
</script>
